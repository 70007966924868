/* NavbarSign.module.css */
.navbarSign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbarSign p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbarSign button {
    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 20px;
    border-radius: 5px;
}

.loginButton {
    background: #007bff;
}

.registerButton {
    background: #28a745;
}

.logoutButton {
    background: #dc3545;
}
