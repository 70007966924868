.forgotPassword {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.forgotPasswordContainer {
    text-align: center;
    background: white;
    padding: 40px 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 450px;
    transition: box-shadow 0.3s ease;
}

.forgotPasswordContainer:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.forgotPasswordContainer h1 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
}

.inputField {
    display: block;
    margin: 15px auto;
    padding: 15px;
    width: 85%;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.inputField:focus {
    border-color: #007bff;
    outline: none;
}

.resetButton {
    margin-top: 20px;
    padding: 15px 30px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.resetButton:hover {
    background-color: #0056b3;
}

.successMessage {
    margin-top: 20px;
    color: green;
}
