/* NavbarLinks.module.css */
.navbarLinksContainer {
    display: flex;
    flex-direction: row;
}

.navbarLinksContainer p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}
