.register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.registerContainer {
  text-align: center;
  background: white;
  padding: 40px 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 450px;
  transition: box-shadow 0.3s ease;
}

.registerContainer:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.registerContainer h1 {
  margin-bottom: 20px;
  font-size: 28px;
  color: #333;
}

.inputField {
  display: block;
  margin: 15px auto;
  padding: 15px;
  width: 85%;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.inputField:focus {
  border-color: #007bff;
  outline: none;
}

.passwordContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleButton {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #007bff;
  outline: none;
}

.line {
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  color: #555;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navButton {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navButton:hover {
  background-color: #0056b3;
}

.submitButton {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #218838;
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}
