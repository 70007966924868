

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.footerLinks {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
}

.footerLinks div {
    width: 250px;
    margin: 1rem;
}

.footerLinksLogo {
    display: flex;
    flex-direction: column;
}

.footerLinksLogo img {
    width: 118px;
    height: 30px;
    margin-bottom: 1rem;
}

.footerLinksLogo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
}

.footerLinksDiv {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.footerLinksDiv h4 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;
    margin-bottom: 0.9rem;
}

.footerLinksDiv p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;
    margin: 0.5rem 0;
    cursor: pointer;
}

.footerCopyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.footerCopyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}

@media screen and (max-width: 850px) {
    .footerHeading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .footerHeading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .footerLinks div {
        margin: 1rem 0;
    }

    .footerBtn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .footerHeading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
